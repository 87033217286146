
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '@/views/SignupView.vue'
import DeleteAccountView from '@/views/DeleteAccountView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupView
  },
  {
    path: '/deleteaccount',
    name: 'Deleteaccount',
    component: DeleteAccountView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router