<template>
  
  <div class="page-container">
    <div class="header-container">
      <router-link to="/" class="logo-link">
        <span class="logo"></span>
      </router-link>
    </div>
    <div class="signup-container">
      <form @submit.prevent="submit" class="signup-form">
        <label for="email">
          <span>이메일</span>
          <input type="text" id="email" placeholder="aaa@example.com" v-model="state.form.email"
            @blur="validateEmail" />
          <span v-if="state.emailError" class="error-message">{{ state.emailError }}</span>
        </label>
        <label for="password">
          <span>패스워드</span>
          <input type="password" id="password" v-model="state.form.password" @blur="validatePassword" />
          <span v-if="state.passwordError" class="error-message">{{ state.passwordError }}</span>
        </label>
        <label for="name">
          <span>이름</span>
          <input type="text" id="name" v-model="state.form.name" @blur="validateName" />
          <span v-if="state.nameError" class="error-message">{{ state.nameError }}</span>
        </label>
        <label for="mobile">
          <span>전화번호</span>
          <input type="tel" id="mobile" placeholder="01012345678" v-model="state.form.mobile" @input="validateMobile" />
          <span v-if="state.mobileError" class="error-message">{{ state.mobileError }}</span>
        </label>
        <div class="terms-container">
          <h3>약관 및 정책</h3>
          <div class="terms-content">
            <p>제1조 (목적) 이 약관은 MZTOX(이하 "회사"라 합니다)가 제공하는 번역서비스(이하 "서비스"라 합니다)와 관련하여, 회사와 회원 간의 권리와 의무, 책임사항, 이용조건 및 절차 등을 규정하는 것을 목적으로 합니다.</p>
            <p>제2조 (회원가입) 회원가입은 회사가 정한 절차에 따라 신청합니다. 회원은 회사에서 요청하는 정보(이름, 이메일, 비밀번호, 전화번호)를 제공하여야 합니다.</p>
            <p>제3조 (개인정보의 수집 및 이용 목적) 회사는 다음과 같은 목적을 위해 개인정보를 수집합니다: 이름: 회원 식별 및 서비스 제공 이메일: 회원 식별, 서비스 제공, 중복 검사 비밀번호: 회원 계정 보호 전화번호: 회원 식별, 서비스 제공, 중복 검사</p>
            <p>제4조 (개인정보의 보관 및 보호) 수집된 개인정보는 회사의 데이터베이스에 안전하게 저장됩니다. 특히, 비밀번호는 암호화되어 저장되며, 회원의 계정 보호를 위해 안전하게 관리됩니다. 이메일과 전화번호는 중복 검사 목적으로만 사용되며, 그 외의 용도로는 사용되지 않습니다.</p>
            <p>제5조 (회원의 의무) 회원은 본인의 개인정보가 정확하게 관리되도록 최선을 다해야 합니다. 타인의 정보를 도용하거나 허위 정보를 제공해서는 안 됩니다.</p>
            <p>제6조 (회사의 의무) 회사는 회원의 개인정보를 보호하기 위해 노력합니다. 회사는 회원이 제공한 개인정보를 회원의 동의 없이 제3자에게 제공하지 않습니다. 단, 법령에 의해 요구되는 경우는 예외로 합니다.</p>
            <p>제7조 (약관의 변경) 회사는 필요 시 약관을 변경할 수 있으며, 변경된 약관은 적용일자 및 변경 사유를 명시하여 공지합니다. 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있습니다. 변경된 약관의 효력 발생일 이후에도 서비스를 계속 이용할 경우 변경된 약관에 동의한 것으로 간주합니다.</p>
            <p>부칙 이 약관은 2024년 8월 1일부터 시행됩니다.</p>
          </div>
          <label>
            <input type="checkbox" v-model="state.agreedTerms" />
            약관에 동의합니다.
          </label>
        </div>
        <hr />
        <button type="submit" id="signupBtn">회원가입</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    const state = reactive({
      form: {
        email: "",
        password: "",
        name: "",
        mobile: ""
      },
      emailError: "",
      passwordError: "",
      nameError: "",
      mobileError: "",
      agreedTerms: false
    });

    const isFormValid = computed(() => {
      return !state.emailError && !state.passwordError && !state.nameError && !state.mobileError && state.agreedTerms;
    });

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!state.form.email) {
        state.emailError = "이메일을 입력해주세요.";
      } else if (!emailRegex.test(state.form.email)) {
        state.emailError = "올바른 이메일 형식이 아닙니다.";
      } else {
        state.emailError = "";
      }
    };

    const validatePassword = () => {
      const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

      if (!state.form.password) {
        state.passwordError = "비밀번호를 입력해주세요.";
      } else if (state.form.password.length > 100) {
        state.passwordError = "비밀번호는 최대 100자 이하이어야 합니다.";
      } else if (specialCharacterRegex.test(state.form.password)) {
        state.passwordError = "비밀번호에 특수기호를 사용할 수 없습니다.";
      } else {
        state.passwordError = "";
      }
    };

    const validateName = () => {
      if (!state.form.name) {
        state.nameError = "이름을 입력해 주세요.";
      } else if (state.form.name.length < 2 || state.form.name.length > 50) {
        state.nameError = "이름은 최소 2자 이상, 최대 50자 이하이어야 합니다.";
      } else {
        state.nameError = "";
      }
    };

    const validateMobile = (event) => {
      const value = event.target.value.replace(/[^0-9]/g, '');
      state.form.mobile = value;

      const mobileRegex = /^[0-9]{10,11}$/;
      if (!value) {
        state.mobileError = "전화번호를 입력해 주세요.";
      } else if (!mobileRegex.test(value)) {
        state.mobileError = "유효한 전화번호를 입력해 주세요.";
      } else {
        state.mobileError = "";
      }
    };
    const submit = async () => {
      if (!state.agreedTerms) {
        alert("약관에 동의 후 회원가입해 주세요.");
        return;
      }

      try {
        await axios.post("/api/signup", state.form, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        alert("회원가입에 성공했습니다.");
        router.push('/login');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data;
          if (errorMessage.msg) {
            alert(`중복된 회원 정보가 있습니다: ${errorMessage.msg}`);
          }
          alert(`회원가입에 실패했습니다: ${errorMessage.email} ${errorMessage.password} ${errorMessage.name} ${errorMessage.mobile}`);
        } else {
          alert("회원가입에 실패했습니다. 네트워크 상태를 확인해주세요.");
        }
        // console.error("회원가입 실패", error);
      }
    };

    return { state, submit, validateEmail, validateName, validateMobile, validatePassword, isFormValid };
  },
};
</script>

<style>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: none;
}

.logo-link {
  display: inline-block;
  cursor: pointer;
}

.logo {
  background-image: url('../assets/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 80px;
  display: inline-block;
}

.signup-container {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.signup-form {
  background-color: #fff;
  color: #344760;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4679BD;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.terms-container {
  margin: 10px 0;
}

.terms-content {
  font-size: 14px;
  margin-bottom: 10px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.terms-content p {
  margin: 5px 0;
}

hr {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #eee;
}
</style>